.testimonialsPeople {
  margin-top: 96px;
  margin-bottom: 64px;
}

.testimonialsPeopleTitle {
  color: var(--MAROON, #952043);
  text-align: center;
  font-family: Satoshi Variable;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%; /* 40.96px */
}

/* Media query for desktop */
@media (min-width: 768px) {
}


/* Media query for mobile */
@media (max-width: 767px) {
  .testimonialsPeopleTitle {
    font-size: 24px;
  }
  .testimonialsPeople {
    margin-top: 40px;
  }
 }
