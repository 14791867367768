.footer {
  background-color: #c59771;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto; /* Use 100vh for full viewport height */
  width: 100%;
  text-align: center; /* Optional: Center content */
  color: white; /* Optional: Text color */
  border-radius: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.hr {
  background-color: #ffffff;
  width: 100%;
  height: 1px;
  margin-bottom: 30px;
}

.vr {
  background-color: #ffffff;
  width: 1px;
  height: 5px;
}

/* Media query for desktop */
@media (min-width: 768px) {
  .instaIcon {
    margin-left: 40px;
  }
  .hr {
    margin-top: 40px;
  }
}


/* Media query for mobile */
@media (max-width: 767px) {
  .instaIcon {
    margin-left: 10px;
  }
  .hr {
    margin-top: 20px;
  }
 }