.testimonialsTitle {
  color: var(--MAROON, #952043);
  text-align: center;
  font-family: Satoshi Variable;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%; /* 30.72px */
}

.testimonialVr {
  background-color: #952043;
  width: 2px;
  height: 64px;
  align-items: center;
}

.testimonials {
  padding-top: 64px;
  padding-bottom: 64px;
}

/* Media query for desktop */
@media (min-width: 768px) {
}


/* Media query for mobile */
@media (max-width: 767px) {
  .testimonialsTitle {
    font-size: 18px;
    font-weight: 400;
  }
  .testimonialVr {
    height: 30px;
  }
 }
