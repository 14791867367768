.testimonialsPeopleCard {
  margin-top: 40px;
  border-radius: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: 2px solid #c59771;
}

.personName {
  color: var(--MAROON, #952043);
  text-align: center;
  font-family: Satoshi Variable;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  margin-top: 20px;
}

.testimonialsPeopleCardDescription {
  color: var(--SLATE, #222);
  text-align: center;
  font-family: Satoshi Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
/* Media query for desktop */
@media (min-width: 768px) {
}


/* Media query for mobile */
@media (max-width: 767px) {
  .testimonialPeopleImage {
    width: 100px;
  }
  .testimonialsPeopleCard {
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .personName {
    font-size: 20px;
  }
  .testimonialsPeopleCardDescription {
    color: var(--SLATE, #222);
    font-size: 14px;
  }
 }

