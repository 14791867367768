.eventStep {
  border-radius: 16px;
  border: 1px solid var(--MAROON, #952043);
  margin-top: 24px;
}

.eventNumber {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background: #f3d9c4;
}

.eventStepTitle1 {
  color: var(--MAROON, #952043);
  text-align: center;
  font-family: Satoshi Variable;
  font-style: normal;
  font-weight: 500;
  line-height: 128%; /* 30.72px */
  text-align: start !important;
}

.eventStepTitle2 {
  color: var(--MAROON, #952043);
  font-family: Satoshi Variable;
  font-style: normal;
  font-weight: 400;
  line-height: 128%; /* 20.48px */
  text-align: start !important;
}

.number {
  color: var(--MAROON, #952043);
  text-align: center;
  font-family: Satoshi Variable;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 40.96px */
}

/* Media query for desktop */
@media (min-width: 768px) {
  .eventStep {
    padding: 24px;
  }
  .eventNumber {
    width: 72px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 17px 0px;
  }
  .eventStepTitle1 {
    font-size: 24px;
    margin-bottom: 24px;
    padding-top: 24px;
  }
  .eventStepTitle2 {
    font-size: 16px;
  }
  .number {
    font-size: 32px;
  }
}


/* Media query for mobile */
@media (max-width: 767px) {
  .eventStep {
    padding: 8px;
  }
  .eventNumber {
    width: 70px;
    padding: 17px 0px;
    display: none;
  }
  .eventStepTitle1 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .eventStepTitle2 {
    font-size: 14px;
    display: none;

  }
  .number {
    font-size: 20px;
    display: none;
  }
 }



