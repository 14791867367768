.eventsTitle {
  color: var(--MAROON, #952043);
  text-align: center;
  font-family: Satoshi Variable;
  font-style: normal;
  font-weight: 500;
  line-height: 128%; /* 40.96px */
}
.event_title {
  padding-top: 40px;
}

.styleEvent {
  color: var(--MAROON, #952043);
  font-family: Satoshi Variable;
  font-style: normal;
  font-weight: 400;
  line-height: 116%; /* 83.52px */
}

.eventVr {
  background-color: #952043;
  width: 2px;
  align-items: center;
}

/* Media query for desktop */
@media (min-width: 768px) {
  .eventsTitle {
    font-size: 32px;
  }
  .eventVr {
    height: 64px;
  }
  .styleEvent {
    font-size: 72px;
  }
}


/* Media query for mobile */
@media (max-width: 767px) {
  .eventsTitle {
    font-size: 16px;
    font-weight: 400;
  }
  .eventVr {
    height: 30px;
  }
  .styleEvent {
    font-size: 28px;
  }
 
 }



