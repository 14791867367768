.aboutImage {
  /* border-radius: 10px 10px 10px 10px; */
  padding-top: 40px;
  object-fit: cover;
  box-sizing: border-box;
}

.about {
  padding-top: 32px;
  padding-bottom: 32px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 64px;
}


.about1 {
  color: var(--MAROON, #952043);
  font-family: Satoshi Variable;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 55.68px */
  align-content: center;
  text-align: start !important;
  padding-top:44px;
}


.aboutDescription1,
.aboutDescription2 {
  color: var(--Gray-1, #333);
  font-family: Satoshi Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 50;
  line-height: 100%; /* 27.6px */
  padding-top: 16px;
  text-align: start !important;
}

.aboutHr {
  background-color: #c59771;
  width: 100%;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 28px;
  margin-left: 80px;
}

/* Media query for desktop */
@media (min-width: 768px) {
  .aboutImage {
    width: 100%;
    height: 380px;
  }
}


/* Media query for mobile */
@media (max-width: 767px) {
  .aboutImage {
    width: 100%;
    height: 250px;
    border-radius: 10px;
  }
  .about {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .about1 {
    font-size: 28px;
    padding-top:20px;
  }
  .aboutDescription1,
  .aboutDescription2 {
  color: var(--Gray-1, #333);
  font-family: Satoshi Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 25px; /* 27.6px */
  padding-top: 10px;
  text-align: start !important;
}
 }
