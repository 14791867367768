.styleService {
  color: var(--MAROON, #952043);
  font-family: Satoshi Variable;
  font-style: normal;
  font-weight: 400;
  line-height: 116%; /* 83.52px */
}

.serviceVr {
  background-color: #952043;
  width: 2px;
  align-items: center;
}

/* Media query for desktop */
@media (min-width: 768px) {
  .styleService {
    font-size: 72px;
  }
  .servicesTitle {
    font-size: 32px;
  }
  .serviceVr {
    height: 64px;
  }
  .services {
    padding-top: 64px;
    padding-bottom: 24px;
  }
 
}

/* Media query for mobile */
@media (max-width: 767px) {
  .styleService {
    font-size: 28px;
  }
  .servicesTitle {
    font-size: 18px;
  }
  .serviceVr {
    height: 30px;
  }
  .services {
    padding-top: 30px;
    padding-bottom: 0px;
  }
}