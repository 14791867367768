.servicesTitle {
  color: var(--MAROON, #952043);
  text-align: center;
  font-family: Satoshi Variable;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%; /* 40.96px */
}

.styleService {
  color: var(--MAROON, #952043);
  font-family: Satoshi Variable;
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: 116%; /* 83.52px */
}

.serviceVr {
  background-color: #952043;
  width: 2px;
  height: 64px;
  align-items: center;
}

.homeServiceImages {
  margin-top: 24px;
}

/* Media query for desktop */
@media (min-width: 768px) {
  .HomeServices {
    margin-top: 50px;
  }
}


/* Media query for mobile */
@media (max-width: 767px) {
  .HomeServices {
    margin-top: 50px;
  }
 }


