.homeServicesBackgroundImage {
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 260px; 
  width: 100%;
  text-align: center; 
  color: white; 
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  filter: grayscale(100%);
  background-size: cover;
}

.homeServicesBackgroundImage:hover {
  filter: grayscale(0%);
}

.homeServicesTitle {
  color: #fff;
  text-align: center;
  font-family: Satoshi Variable;
  font-size: 32px;
  font-style: bold;
  font-weight: 500;
  line-height: 32px;

}
.homeServicesBackgroundImage .container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.homeServicesBackgroundImage .arrowIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 25px; 
  margin-right: 36px; 
}


/* Media query for desktop */
@media (min-width: 768px) {
}


/* Media query for mobile */
@media (max-width: 767px) {
  .homeServicesBackgroundImage {
    height: 150px;
  }
  .homeServicesTitle {
    font-size: 20px;
  }
 }



