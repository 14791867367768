.eventDescriptionImage {
  border-radius: 10px;
  height: 300px;
  object-fit: cover;
  width: 70%;
}

.eventDescription1 {
  color: var(--Gray-1, #333);
  font-family: Satoshi Variable;
  font-style: normal;
  font-weight: 400;
  line-height: 138%; /* 27.6px */
  padding-top: 20px;
  text-align: start !important;
}

.eventDescriptionContactButton {
  display: flex;
  padding-top: 12px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 32px;
  background-color: var(--MAROON, #952043);
}

/* Media query for desktop */
@media (min-width: 768px) {
  .eventDescription {
    padding-top: 32px;
  }
  .eventDescription1 {
    font-size: 20px;
  }
  .eventDescriptionContactButton {
    margin-top: 50px;
  }
  .eventDescription {
    padding-bottom: 32px;
  }  
}


/* Media query for mobile */
@media (max-width: 767px) {
  .eventDescriptionContactButton {
    margin-top: 20px;
  }
  .eventDescriptionImage {
    width: 100%;
    height: 300px;
  }
 }



