.carouselBackgroundImage {
  background-image: url("../../../public/Images/home_background.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Use 100vh for full viewport height */
  width: 100%;
  text-align: center; /* Optional: Center content */
  color: white; /* Optional: Text color */
}

.carouselContent {
  background-color: #ffffff;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
}

.carouselDescription {
  color: var(--SLATE, #222);
  font-family: Satoshi Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 142.857% */
  text-align: start !important;
  margin-top: 20px;
}

.carouselText1 {
  color: var(--MAROON, #952043);
  font-family: Satoshi Variable;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 171.429% */
  margin-top: 20px;
  text-align: start !important;
}

.carouselText2 {
  color: var(--MAROON, #952043);
  text-align: center;
  font-family: Satoshi Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-align: start !important;
}

/* Media query for desktop */
@media (min-width: 768px) {
}


/* Media query for mobile */
@media (max-width: 767px) {
  .carouselBackgroundImage {
    height: 70vh; /* Use 100vh for full viewport height */
  }
  .carouselDescription {
    font-size: 16px;
    line-height: 26px;
  }
  .carouselText1 {
    font-size: 18px;
    margin-top: 10px;
    line-height: 0px;
  }
  .carouselText2 {
    font-size: 14px;
    margin-top: 10px;
  }
 }

